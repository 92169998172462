import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Center,
  Stack,
  Flex,
  Image,
  Grid,
  FormControl,
  FormLabel,
  Progress,
  FormHelperText,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import HtmlContainer from '@components/HtmlContainer'

import { Box, Spacer, Text } from '@chakra-ui/layout'
import { Icon } from '@chakra-ui/react'
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { isEmpty, get, findIndex } from 'lodash'
import { ButtonPrimary, Select, Input } from '@components/Base'
import { PendingImageCard } from '@components/Cards'
import { uploadIcon } from '@assets/svg/'
import * as API from '@api'
import { useTranslation } from 'react-i18next'

const LIMIT = parseInt(process.env.REACT_APP_UPLOAD_LIMIT || 5)

const ImageForm = ({ data, user, onClose = () => {}, setStep = () => {}, setFiles = () => {} }) => {
  const { t } = useTranslation()
  const [language, setLanguage] = useState('en-US')
  const [uploadError, setUploadError] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [progressInfos, setProgressInfos] = useState([])
  const [pending, setPending] = useState([])
  const [subjects, setSubjects] = useState([])
  const [subject, setSubject] = useState('')
  const [author, setAuthor] = useState('')
  const [fileProgressInfo, setFileProgressInfo] = useState(undefined)
  const [tmpImage, setTmpImage] = useState(undefined)
  const [isLoading, setIsloading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isNext, setIsNext] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      setUploadError('')
      if (files.length > 0) {
        setSelectedFiles(files)
      }
    },
    onDropRejected: (arr) => {
      reset()
      setUploadError(get(arr[0], 'errors[0].code'))
    },
    multiple: false,
    maxSize: LIMIT * 1048576 || 5242880,
    maxFiles: 1,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
  })

  const processImage = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => cb(reader.result)
    reader.onerror = () => {} //(error) => console.log('[ImageForm.processImage]', error)
  }

  const updateProgress = () => {
    if (fileProgressInfo) {
      let _progressInfos = [...progressInfos]
      const percentage = get(fileProgressInfo, 'percentage', 0)
      let icon = get(fileProgressInfo, 'icon')
      if (!icon && percentage === 100) {
        icon = <Icon as={CheckIcon} w={4} h={4} color="green.500" />
      } else if (!icon && percentage < 100) {
        icon = undefined
      }
      const p = {
        fileName: get(fileProgressInfo, 'fileName'),
        percentage,
        icon,
      }
      const index = findIndex(_progressInfos, { fileName: get(fileProgressInfo, 'fileName') })
      if (index !== -1) {
        _progressInfos[index] = p
      } else {
        _progressInfos = [p, ...progressInfos]
      }
      setProgressInfos(_progressInfos)
    }
  }

  useEffect(() => {
    setFiles(pending)
  }, [pending])

  const deletePendingFile = (id) => {
    const arr = pending.filter((p) => id !== p.id)
    setPending(arr)
    if (isEmpty(arr)) {
      reset()
    }
  }

  const upload = async (idx, file, metadata) => {
    try {
      const [response, err] = await API.upload({ file, userId: user.Id, metadata }, (event) => {
        setFileProgressInfo({
          fileName: file.name,
          percentage: Math.round((100 * event.loaded) / event.total),
        })
      })
      if (response) {
        processImage(file, (image) =>
          setPending([
            ...pending,
            {
              id: get(response, 'data.Id'),
              image,
              title: file.name,
              subject: `${t(
                get(
                  subjects.filter((s) => s.identifier === get(metadata, 'subject'))[0],
                  'identifier'
                )
              )}`,
            },
          ])
        )

        if (!response.data || get(response, 'data.error')) {
          setFileProgressInfo({
            fileName: file.name,
            percentage: 0,
            icon: <Icon as={SmallCloseIcon} w={4} h={4} color="red.500" />,
          })
        } else {
          setFileProgressInfo({
            fileName: file.name,
            percentage: 100,
          })
        }
      }
      if (err) {
        setUploadError(err)
      }
    } catch (e) {
      setFileProgressInfo({
        fileName: file.name,
        percentage: 0,
        icon: <Icon as={SmallCloseIcon} w={4} h={4} color="red.500" />,
      })
      // console.log('[ImageForm.upload]', e)
    }
  }

  useEffect(() => {
    updateProgress()
  }, [fileProgressInfo])

  useEffect(() => {
    setIsDisabled(
      // isEmpty(title) ||
      isEmpty(subject) || isEmpty(selectedFiles) || isLoading
    )
  }, [
    // title,
    subject,
    selectedFiles,
    isLoading,
  ])

  const uploadFiles = async () => {
    setIsloading(true)
    for (let i = 0; i < selectedFiles.length; i++) {
      await upload(i, selectedFiles[i], {
        // title,
        subject,
        quality: 'MTP.Quality.QUA_MEDIUM',
        relateddInspecId: user.inspecId,
        author,
        provider: 'MTP.Provider.PROV_AUTRE',
        otherProvider: get(user, 'poiName', ''),
      })
    }
    setIsloading(false)
    setUploaded(true)
  }

  const getSubjectOptions = async () => {
    const [result] = await API.getSubjectOptionsList(language)
    if (result) {
      setSubjects(result)
    }

    // if (err) {
    //   console.log('[ImageForm.getSubjectOptions]', err)
    // }
  }

  const getData = async () => {
    await getSubjectOptions()
  }

  const reset = () => {
    setSelectedFiles(undefined)
    setFileProgressInfo(undefined)
    setProgressInfos([])
    setUploaded(false)
    setIsNext(false)
    setTmpImage(undefined)
    setSubject('')
    setAuthor('')
    setUploadError('')
  }

  const close = () => {
    reset()
    onClose()
    setStep(0)
    setPending([])
  }
  const cancel = () => {
    if (!isEmpty(pending)) {
      reset()
      setUploaded(true)
      setIsNext(true)
    } else {
      close()
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data) {
      setSubject(get(data, 'subject') || '')
      setAuthor(get(data, 'author') || '')
    }

    if (user) {
      setLanguage(get(user, 'spokenLanguages[0]'))
    }
  }, [data, user])

  return (
    <>
      {!isEmpty(uploadError) && (
        <Alert status="error" mb="10px">
          <AlertIcon />
          <AlertTitle mr={2}>{t('upload-error')}</AlertTitle>
          <AlertDescription>{t(uploadError, { limit: LIMIT })}</AlertDescription>
        </Alert>
      )}

      {!isEmpty(progressInfos) || isNext ? (
        <>
          {pending &&
            pending.map((p, i) => (
              <PendingImageCard
                key={`${i}-${p.id}`}
                id={p.id}
                image={p.image}
                subject={p.subject}
                title={p.title}
                deleteAction={deletePendingFile}
              />
            ))}
          {isLoading &&
            progressInfos.map((progressInfo, index) => (
              <Flex key={index}>
                <Box flex={1}>
                  <Text>{progressInfo.fileName}</Text>
                  <Progress hasStripe value={progressInfo.percentage || 0} colorScheme="red" />
                </Box>
                <Box pl={4} pt={4}>
                  {progressInfo.icon && <>{progressInfo.icon}</>}
                </Box>
              </Flex>
            ))}
        </>
      ) : (
        <Flex direction={['column', 'row']}>
          <Box w={['100%', '50%']}>
            <section className="container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <Box cursor="pointer">
                  <Center
                    h={['108px', '263px']}
                    borderRadius={4}
                    border="2px dashed #CBD5E0"
                    {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {selectedFiles && Array.isArray(selectedFiles) && selectedFiles.length ? (
                      <Box>
                        {selectedFiles.length > 3 ? (
                          <Text>{`${selectedFiles.length} files`}</Text>
                        ) : (
                          selectedFiles.map((file) => {
                            processImage(file, setTmpImage)
                            var sizeInMB = (get(file, 'size', 0) / (1024 * 1024)).toFixed(2)
                            return (
                              <Box
                                justifyContent={'center'}
                                alignContent={'center'}
                                key={get(file, 'name')}
                                borderRadius={4}
                                w={['100px', '300px']}
                                h={['65px', '220px']}
                                boxShadow="lg">
                                <Image
                                  borderTopRadius={4}
                                  borderBottomRadius={[4, 0]}
                                  src={tmpImage}
                                  objectFit="cover"
                                  w="100%"
                                  h={['65px', '190px']}
                                  mb={['0', '5px']}
                                />
                                <Flex display={['none', 'flex']} justifyContent={'space-between'}>
                                  <Text
                                    isTruncated
                                    fontSize={'12px'}
                                    fontWeight={'700'}
                                    maxW={['90px', '200px']}
                                    ml="5px">
                                    {get(file, 'name')}
                                  </Text>
                                  <Text isTruncated fontSize={'12px'} maxW="50px" mr="5px">
                                    {`${sizeInMB} MB`}
                                  </Text>
                                </Flex>
                              </Box>
                            )
                          })
                        )}
                      </Box>
                    ) : (
                      <Stack spacing={1}>
                        <Image src={uploadIcon} boxSize={['40px', '65px']} />
                      </Stack>
                    )}
                  </Center>
                </Box>
              </div>
            </section>
            <Stack spacing={1}>
              <Text textAlign="center" fontWeight="400" fontSize={14} color="michelinPrimary">
                {t('darg-and-drop-msg')}
              </Text>
              <Text textAlign="center" fontWeight="400" fontSize={14} color="michelinPrimary">
                {t('image-type-msg')}
              </Text>
            </Stack>
          </Box>

          <Box pl={[0, 5]} pt={[5, 0]} w={['100%', '50%']}>
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']} gap={6}>
              <FormControl isRequired>
                <FormLabel>{t('upload-form-subject')}</FormLabel>
                <Select
                  placeholder={t('upload-form-subject')}
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}>
                  {subjects.map((s) => (
                    <option key={s.identifier} value={s.identifier}>
                      {t(s.identifier)}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>{t('upload-form-author')}</FormLabel>
                <Input
                  placeholder={t('upload-form-author')}
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                />
                <FormHelperText>{t('upload-form-author-help-text')}</FormHelperText>
              </FormControl>
            </Grid>
            <Flex pt="20px">
              <HtmlContainer html={t('mandatory-fields')} />
            </Flex>
          </Box>
        </Flex>
      )}
      <Flex direction={['column', 'row']} mb={4}>
        <Spacer />
        {!uploaded ? (
          <>
            <ButtonPrimary
              mt={4}
              w={['100%', '190px']}
              mr={[0, 3]}
              isLoading={isLoading}
              onClick={cancel}
              reverse={true}>
              {t('cancel')}
            </ButtonPrimary>
            <ButtonPrimary
              mt={4}
              w={['100%', '190px']}
              reverse={false}
              disabled={isDisabled}
              isLoading={isLoading}
              loadingText={t('validate-loading-text')}
              onClick={uploadFiles}>
              {t('validate')}
            </ButtonPrimary>
          </>
        ) : (
          <>
            <ButtonPrimary
              mt={4}
              w={['100%', '190px']}
              mr={[0, 3]}
              isLoading={isLoading}
              onClick={close}
              reverse={true}>
              {t('cancel-all')}
            </ButtonPrimary>
            <ButtonPrimary mt={4} w={['100%', '190px']} reverse={false} mr={[0, 3]} onClick={reset}>
              {t('add-image-btn')}
            </ButtonPrimary>
            <ButtonPrimary
              mt={4}
              w={['100%', '190px']}
              reverse={false}
              variant="ghost"
              onClick={() => {
                setStep(1)
                reset()
              }}>
              {t('finalize')}
            </ButtonPrimary>
          </>
        )}
      </Flex>
    </>
  )
}

export default ImageForm
